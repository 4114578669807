"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BadgeService = void 0;
exports.parseBadgeLevels = parseBadgeLevels;
exports.parseConditionalBadges = parseConditionalBadges;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class BadgeService {
  static async getBadgeLevels() {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const [res1, res2, res3] = await Promise.all([_http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/game_level_badges`), _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/game_conditional_badges`), _http.HttpService.get('v2', `/users/${_user.UserService.currentUser.id}/game_achievements`)]);
      return {
        levels: parseBadgeLevels(res1),
        conditionalsBadges: parseConditionalBadges(res2),
        daysBadges: res3.map(_ref => {
          let {
            title: key,
            date
          } = _ref;
          return {
            key: key,
            date: (0, _moment.default)(date)
          };
        }).sort((a, b) => a.date.isBefore(b.date) ? -1 : 1).filter(_ref2 => {
          let {
            key
          } = _ref2;
          return _models.dayBadges.includes(key);
        })
      };
    } catch (err) {
      console.error('[BadgeService][getBadgeLevels]', err);
      throw err;
    }
  }
}
exports.BadgeService = BadgeService;
const backendBadgeLevelKeys = ['cycling_distance', 'cycling_max_distance', 'night_bird', 'positive_elevation', 'cycling_days', 'traces_max_total_distance_over_one_month', 'report'];
const badgeLevelKeys = {
  cycling_days: 'regularity',
  cycling_distance: 'cyclingDistance',
  report: 'contributor',
  cycling_max_distance: 'cyclingMaxDistance',
  night_bird: 'nightBird',
  positive_elevation: 'verticalGain',
  traces_max_total_distance_over_one_month: 'cyclingMaxDistanceOverOneMonth'
};
function parseBadgeLevels(_ref3) {
  let {
    level_badges
  } = _ref3;
  return backendBadgeLevelKeys.reduce((res, _key) => {
    const data = level_badges.find(_ref4 => {
      let {
        code
      } = _ref4;
      return code === _key;
    });
    if (data) {
      const {
        level,
        progress,
        level_thresholds
      } = data;
      const key = badgeLevelKeys[_key];
      if (key === 'cyclingMaxDistance' || key === 'cyclingMaxDistanceOverOneMonth') {
        if (level_thresholds) {
          new Array(level).fill(null).forEach((_, index) => {
            res.push({
              key,
              level: index + 1,
              progress: level_thresholds[index],
              maxLevelReached: true,
              nextLevelMinProgress: null
            });
          });
        }
      } else {
        const maxLevelReached = level_thresholds ? level === level_thresholds.length : true;
        const nextLevelMinProgress = maxLevelReached || !level_thresholds ? null : level_thresholds[level];
        res.push({
          key,
          level,
          progress,
          maxLevelReached,
          nextLevelMinProgress
        });
      }
    }
    return res;
  }, []);
}
const conditionalBadgeKeys = {
  altitude_gain_100m: 'verticalGain',
  distance_50km: 'longDistance',
  night_bird: 'nightBird'
};
function parseConditionalBadges(_ref5) {
  let {
    conditional_badges
  } = _ref5;
  return conditional_badges.reduce((res, _ref6) => {
    let {
      code,
      count
    } = _ref6;
    const key = conditionalBadgeKeys[code];
    if (key) {
      res[key] = {
        key,
        count
      };
    }
    return res;
  }, {});
}