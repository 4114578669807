"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTripPotentialOccurrence = exports.CommutingTripOccurrence = exports.CommutingTrip = void 0;
class CommutingTrip {
  constructor(id, title, home, work, bicyclePortions, isWorkToHome) {
    this.id = id;
    this.title = title;
    this.home = home;
    this.work = work;
    this.bicyclePortions = bicyclePortions;
    this.isWorkToHome = isWorkToHome;
  }
  get bicycleDistance() {
    return this.bicyclePortions.reduce((res, _ref) => {
      let {
        distance
      } = _ref;
      return res + distance;
    }, 0);
  }
}
exports.CommutingTrip = CommutingTrip;
class CommutingTripOccurrence {
  constructor(id, commutingTripId, date, day, direction, certified, manuallyFlagged, compensated, excluded) {
    this.id = id;
    this.commutingTripId = commutingTripId;
    this.date = date;
    this.day = day;
    this.direction = direction;
    this.certified = certified;
    this.manuallyFlagged = manuallyFlagged;
    this.compensated = compensated;
    this.excluded = excluded;
  }
}
exports.CommutingTripOccurrence = CommutingTripOccurrence;
class CommutingTripPotentialOccurrence {
  constructor(id, date, day, direction, candidate, certified, manuallyFlagged, enabled, referenceTrip, traceId) {
    this.id = id;
    this.date = date;
    this.day = day;
    this.direction = direction;
    this.candidate = candidate;
    this.certified = certified;
    this.manuallyFlagged = manuallyFlagged;
    this.enabled = enabled;
    this.referenceTrip = referenceTrip;
    this.traceId = traceId;
  }
}
exports.CommutingTripPotentialOccurrence = CommutingTripPotentialOccurrence;