"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backendTypesMap = exports.GeogroupService = void 0;
exports.parseChallenge = parseChallenge;
exports.parseChallengeTemplate = parseChallengeTemplate;
exports.parseGeogroup = parseGeogroup;
exports.typesMap = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _environment = require("../environment");
var _models = require("../models");
var _stats = require("../utils/stats");
var _app = require("./app");
var _http = require("./http");
var _jersey = require("./jersey");
var _partner = require("./partner");
var _publicationStatus = require("./publication-status");
var _stats2 = require("./stats");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const typesMap = exports.typesMap = {
  company: 'COMPANY',
  school: 'SCHOOL',
  association: 'ASSOCIATION',
  city: 'CITY',
  private: 'PRIVATE',
  other: 'ASSOCIATION'
};
const backendTypesMap = exports.backendTypesMap = {
  ASSOCIATION: 'association',
  CITY: 'city',
  COMPANY: 'company',
  PRIVATE: 'private',
  OTHER: 'other',
  SCHOOL: 'school'
};
class GeogroupService {
  static async getGeogroups() {
    let {
      page,
      pageSize,
      parentId,
      query,
      search,
      types,
      onlyLinkedToPartner
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const params = [{
        key: 'page_size',
        value: pageSize || 100
      }];
      if (page) params.push({
        key: 'page',
        value: page
      });
      if (parentId) params.push({
        key: 'parent_id',
        value: parentId
      });
      if (query) params.push({
        key: 'query',
        value: query
      });
      if (search) params.push({
        key: 'search',
        value: search
      });
      if (types) params.push({
        key: 'group_type[]',
        value: types.map(key => typesMap[key]).join(',')
      });
      if (onlyLinkedToPartner !== undefined) params.push({
        key: 'linked_to_partner',
        value: !onlyLinkedToPartner
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('geogroupsV4', `/geogroups`, params);
      const geogroups = results.map(data => parseGeogroup(data, _app.AppService.environment.backendUrl));
      return {
        count,
        hasNext: Boolean(next),
        geogroups
      };
    } catch (err) {
      console.error('[GeogroupService][getGeogroups]', err);
      throw err;
    }
  }
  static async getGeogroup(_ref) {
    let {
      geoGroupId
    } = _ref;
    if (geoGroupId === null) throw new Error('partner has no geogroup identifier');
    try {
      const data = await _http.HttpService.get('geogroupsV4', `/geogroups/${geoGroupId}`);
      return parseGeogroup(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][getGeogroup]', err);
      throw typeof err === 'object' && err && 'detail' in err && typeof err.detail === 'string' ? new Error(err.detail) : err;
    }
  }
  static async getInvitationLink(id) {
    try {
      const data = await _http.HttpService.post('geogroups', `/geogroups/${id}/invitations`);
      const {
        code
      } = data;
      return {
        link: `${_app.AppService.environment.frontendUrl}/communities/invites/${code}`,
        code
      };
    } catch (err) {
      console.error('[GeogroupService][getInvitationLink]', err);
      throw err;
    }
  }
  static async getGeogroupFromInvitationCode(code) {
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/invitations/${code}`);
      return parseGeogroup(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][getGeogroupFromInvitationCode]', err);
      throw err;
    }
  }
  static async getUserGeogroups() {
    let {
      parentId,
      statuses
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const params = [];
      if (parentId) params.push({
        key: 'parent_id',
        value: parentId
      });
      if (statuses) {
        params.push({
          key: 'geogroup_join_type',
          value: statuses.join(',')
        });
      }
      const res = await _http.HttpService.get('v5', `/users/${_user.UserService.currentUser.id}/geogroups`, params);
      const geogroups = res.map(data => parseUserGeogroup(data, _app.AppService.environment.backendUrl));
      return geogroups;
    } catch (err) {
      console.error('[GeogroupService][getUserGeogroups]', err);
      throw err;
    }
  }
  static async getUserGeogroup(id) {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const res = await _http.HttpService.get('v2', `/users/${_user.UserService.currentUser.id}/geogroups/${id}`);
      return parseUserGeogroup(res, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][getUserGeogroup]', err);
      throw err;
    }
  }
  static async updateUserGeogroup(id, _ref2) {
    let {
      userType: user_type
    } = _ref2;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const res = await _http.HttpService.patch('v2', `/users/${_user.UserService.currentUser.id}/geogroups/${id}`, [], [], JSON.stringify({
        user_type
      }));
      return parseUserGeogroup(res, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][updateUserGeogroup]', err);
      throw err;
    }
  }
  static async addGeogroups(_ref3) {
    let {
      partner,
      groups
    } = _ref3;
    try {
      const body = groups.map(_ref4 => {
        let {
          type,
          name,
          address,
          parentId,
          subGroupType,
          siret
        } = _ref4;
        let data = {
          type: typesMap[type],
          title: name,
          code: name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '_'),
          size_range: JSON.stringify({
            min: 0,
            max: 100_000_000
          }),
          automatic_challenge_creation: 'DISABLED'
        };
        if (address) data = {
          ...data,
          geo_point: JSON.stringify(address.point),
          address: address.address
        };
        if (parentId) data.parent = `${parentId}`;
        if (subGroupType) data.sub_group_type = backendSubGroupTypesMap[subGroupType];
        if (siret) data.siret_number = siret;
        return data;
      });
      const res = await _http.HttpService.post('v4', `/geogroups`, [], [], JSON.stringify(body), partner ? {
        partner: partner.code
      } : {});
      return res.map(props => parseGeogroup(props, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[GeogroupService][addGeogroups]', err);
      throw err;
    }
  }
  static async addGeogroup(_ref5) {
    let {
      type,
      name,
      areaId,
      min,
      max,
      address,
      icon,
      automaticChallengeStatus,
      bannerPicture,
      publicationStatus,
      partner,
      parentId,
      subGroupType,
      description,
      linkUrl,
      linkLabel,
      siret
    } = _ref5;
    try {
      let data = {
        type: typesMap[type],
        title: name,
        code: name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '_'),
        size_range: JSON.stringify({
          min: min || 0,
          max: max || 100_000_000
        }),
        automatic_challenge_creation: automaticChallengeStatus ? backendAutomaticChallengeStatusMap[automaticChallengeStatus] : 'disabled'
      };
      if (areaId) data.area = `${areaId}`;
      if (address) data = {
        ...data,
        geo_point: JSON.stringify(address.point),
        address: address.address
      };
      if (publicationStatus === 'published') data.publication_status = 'PUBLISHED';
      if (parentId) data.parent = `${parentId}`;
      if (subGroupType) data.sub_group_type = backendSubGroupTypesMap[subGroupType];
      if (description !== undefined) data = {
        ...data,
        description
      };
      if (linkUrl !== undefined) {
        data = {
          ...data,
          link_url: linkUrl,
          link_label: linkLabel || ''
        };
      }
      if (siret !== undefined) data = {
        ...data,
        siret
      };
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      if (icon) formData.append('photo', icon);
      if (bannerPicture) formData.append('banner_picture', bannerPicture);
      const props = await _http.HttpService.post('geogroupsV4', `/geogroups`, [], [], formData, partner ? {
        partner: partner.code
      } : {});
      return parseGeogroup(props, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][addGeogroup]', err);
      throw err;
    }
  }
  static async updateGeogroup(id, _ref6, partner) {
    let {
      type,
      name,
      min,
      max,
      address,
      icon,
      automaticChallengeStatus,
      bannerPicture,
      description,
      linkUrl,
      linkLabel,
      publicationStatus
    } = _ref6;
    try {
      let data = {};
      if (type) data.type = typesMap[type];
      if (name) data = {
        ...data,
        title: name,
        code: name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '_')
      };
      if (min !== undefined || max !== undefined) {
        data.size_range = JSON.stringify({
          min: min || 0,
          max: max || 100_000_000
        });
      }
      if (automaticChallengeStatus !== undefined) data.automatic_challenge_creation = backendAutomaticChallengeStatusMap[automaticChallengeStatus];
      if (address) data = {
        ...data,
        geo_point: JSON.stringify(address.point),
        address: address.address
      };
      if (description !== undefined) data = {
        ...data,
        description
      };
      if (linkUrl !== undefined) {
        data = {
          ...data,
          link_url: linkUrl,
          link_label: linkLabel || ''
        };
      }
      if (publicationStatus) data.publication_status = _publicationStatus.backendPublicationStatuses[publicationStatus];
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      if (icon !== undefined) formData.append('photo', icon || '');
      if (bannerPicture !== undefined) formData.append('banner_picture', bannerPicture || '');
      const props = await _http.HttpService.patch('geogroupsV4', `/geogroups/${id}`, [], [], formData, partner ? {
        partner: partner.code
      } : undefined);
      return parseGeogroup(props, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][updateGeogroup]', err);
      throw err;
    }
  }
  static async removeGeogroup(id, partner) {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('geogroupsV4', `/geogroups/${id}`, [], [], null, partner?.code ? {
        partner: partner.code
      } : undefined);
    } catch (err) {
      console.error('[GeogroupService][removeGeogroup]', err);
    }
  }
  static async joinGeogroup(id) {
    let {
      disabledRetrieval,
      userId,
      partnerCode,
      firstName,
      lastName
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      if (!userId && !_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v5', `/users/${userId || _user.UserService.currentUser?.id}/geogroups`, [], [], JSON.stringify({
        group: id,
        ...(firstName ? {
          first_name: firstName
        } : {}),
        ...(lastName ? {
          last_name: lastName
        } : {})
      }), partnerCode ? {
        partner: partnerCode
      } : undefined);
      if (!disabledRetrieval && typeof data.group === 'number') {
        data.group = await _http.HttpService.get('geogroupsV4', `/geogroups/${id}`);
      }
      return parseUserGeogroup(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][joinGeogroup]', err);
      throw err;
    }
  }
  static async joinGeogroupWithInvitation(code) {
    let {
      geogroupId
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const data = await _http.HttpService.post('v2', `/users/${_user.UserService.currentUser.id}/geogroups/invitations/${code}`, geogroupId ? [{
        key: 'group',
        value: geogroupId
      }] : []);
      return parseUserGeogroup(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[GeogroupService][joinGeogroupWithInvitation]', err);
      return null;
    }
  }
  static async leaveGeogroup(id) {
    let {
      userId,
      partnerCode
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      if (!userId && !_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('geogroups', `/users/${userId || _user.UserService.currentUser?.id}/geogroups/${id}`, [], [], null, partnerCode ? {
        partner: partnerCode
      } : undefined);
    } catch (err) {
      console.error('[GeogroupService][leaveGeogroup]', err);
      throw err;
    }
  }
  static async getMembers(geoGroupId, _ref7) {
    let {
      partner,
      page,
      pageSize,
      orderBy,
      order,
      registrationStartDate,
      registrationEndDate,
      onlyNew,
      joinTypes,
      userType
    } = _ref7;
    try {
      const params = [];
      if (orderBy && order) {
        let value;
        if (orderBy === 'username') value = 'user__username';else if (orderBy === 'joinDate') value = 'join_date';else if (orderBy === 'distance') value = 'meters';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (registrationStartDate || registrationEndDate) {
        if (registrationStartDate) params.push({
          key: 'date_start',
          value: registrationStartDate.format('YYYY-MM-DD')
        });
        if (registrationEndDate) params.push({
          key: 'date_end',
          value: registrationEndDate.format('YYYY-MM-DD')
        });
      } else if (onlyNew) params.push({
        key: 'only_new',
        value: true
      });
      if (joinTypes) {
        params.push(...joinTypes.map(key => ({
          key: 'join_type[]',
          value: backendUserGeogroupStatuses[key]
        })));
      }
      if (userType) {
        params.push({
          key: 'user_type',
          value: userType
        });
      }
      const data = await _http.HttpService.get('geogroupUsers', `/geogroups/${geoGroupId}/users`, [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, ...params], [], null, {
        partner: partner?.code
      });
      const users = data.results.reduce((res, userData) => {
        const user = parseGeogroupMember(userData);
        if (user) res.push(user);
        return res;
      }, []);
      return {
        count: data.count,
        users,
        hasNext: Boolean(data.next)
      };
    } catch (err) {
      console.error('[GeogroupService][getMembers]', err);
      throw err;
    }
  }
  static async getMembersCount(geoGroupId, _ref8) {
    let {
      partner,
      registrationStartDate,
      registrationEndDate,
      joinTypes
    } = _ref8;
    try {
      const params = [];
      if (registrationStartDate || registrationEndDate) {
        if (registrationStartDate) params.push({
          key: 'date_start',
          value: registrationStartDate.format('YYYY-MM-DD')
        });
        if (registrationEndDate) params.push({
          key: 'date_end',
          value: registrationEndDate.format('YYYY-MM-DD')
        });
      }
      if (joinTypes) {
        params.push(...joinTypes.map(key => ({
          key: 'join_type[]',
          value: backendUserGeogroupStatuses[key]
        })));
      }
      const {
        count
      } = await _http.HttpService.get('v2', `/geogroups/${geoGroupId}/users_count`, params, [], null, {
        partner: partner?.code
      });
      return {
        count
      };
    } catch (err) {
      console.error('[GeogroupService][getMembersCount]', err);
      throw err;
    }
  }
  static async removeMember(_ref9, _ref10) {
    let {
      geoGroupId
    } = _ref9;
    let {
      id
    } = _ref10;
    try {
      await _http.HttpService.delete('geogroups', `/geogroups/${geoGroupId}/users/${id}`);
      return true;
    } catch (err) {
      console.error('[GeogroupService][removeMember]', err);
      throw err;
    }
  }
  static async getChallenges(geoGroupId, _ref11) {
    let {
      period,
      page,
      pageSize,
      query,
      state,
      order
    } = _ref11;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'target_type[]',
        value: 'TRAVELED_DISTANCE'
      }, {
        key: 'target_type[]',
        value: 'CYCLING_DAYS'
      }, {
        key: 'target_type[]',
        value: 'TRACES_COUNT'
      }];
      if (query) params.push({
        key: 'query',
        value: query
      });
      if (period?.type === 'custom') params.push(...(0, _models.periodToParams)(period.toIPeriod()));
      if (state) state.forEach(value => params.push({
        key: 'state[]',
        value
      }));
      if (order) params.push({
        key: 'ordering',
        value: order
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('geogroupChallenges', `/geogroups/${geoGroupId}/challenges`, params);
      const challenges = results.reduce((res, data) => {
        const challenge = parseChallenge(data, _app.AppService.environment.backendUrl);
        if (challenge) res.push(challenge);
        return res;
      }, []);
      return {
        challenges,
        count,
        hasNext: Boolean(next)
      };
    } catch (err) {
      console.error('[GeogroupService][getChallenges]', err);
      throw err;
    }
  }
  static async getChallenge(geogroupId, challengeId) {
    try {
      const data = await _http.HttpService.get('geogroupChallenges', `/geogroups/${geogroupId}/challenges/${challengeId}`);
      const challenge = parseChallenge(data, _app.AppService.environment.backendUrl);
      if (!challenge) throw new Error('challenge type not taken into account');
      return challenge;
    } catch (err) {
      console.error('[GeogroupService][getChallenge]', err);
      throw err;
    }
  }
  static async addChallenge(_ref12, props, photo) {
    let {
      code,
      geoGroupId
    } = _ref12;
    let {
      expectedStatus
    } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    try {
      if (!geoGroupId) throw new Error('invalid geogroupId');
      if (!props.event && !props.collaboration_type) props.collaboration_type = 'EVERYONE';
      let body;
      if (photo) {
        body = new FormData();
        body.append('photo', photo);
        body.append('data', JSON.stringify(props));
      } else {
        body = JSON.stringify(props);
      }
      const result = await _http.HttpService.post('geogroupChallenges', `/geogroups/${geoGroupId}/challenges`, [], [], body, code ? {
        partner: code,
        expectedStatus
      } : undefined);
      const challenge = parseChallenge(result, _app.AppService.environment.backendUrl);
      if (!challenge) throw new Error('challenge type not taken into account');
      return challenge;
    } catch (err) {
      console.error('[GeogroupService][addChallenge]', err);
      throw err;
    }
  }
  static async updateChallenge(_ref13, props, photo) {
    let {
      code,
      geoGroupId
    } = _ref13;
    try {
      let body;
      if (photo || photo === null) {
        body = new FormData();
        body.append('photo', photo || '');
        body.append('data', JSON.stringify(props));
      } else {
        body = JSON.stringify(props);
      }
      const data = await _http.HttpService.put('geogroupChallenges', `/geogroups/${geoGroupId}/challenges/${props.id}`, [], [], body, {
        partner: code
      });
      const challenge = parseChallenge(data, _app.AppService.environment.backendUrl);
      if (!challenge) throw new Error('challenge type not taken into account');
      return challenge;
    } catch (err) {
      console.error('[GeogroupService][updateChallenge]', err);
      throw err;
    }
  }
  static async removeChallenge(_ref14, id) {
    let {
      code,
      geoGroupId
    } = _ref14;
    try {
      await _http.HttpService.delete('geogroupChallenges', `/geogroups/${geoGroupId}/challenges/${id}`, [], [], null, {
        partner: code
      });
      return true;
    } catch (err) {
      console.error('[GeogroupService][removeChallenge]', err);
      throw err;
    }
  }
  static async getChallengeLeaderboard(challengeId, _ref15) {
    let {
      page,
      pageSize,
      subgroup,
      userId
    } = _ref15;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }];
      if (subgroup) params.push({
        key: 'subgroup_id',
        value: subgroup.id
      });
      if (userId) params.push({
        key: 'user_id',
        value: userId
      });
      const {
        results,
        next,
        count
      } = await _http.HttpService.get('challengesLeaderboard', `/challenges/${challengeId}/leaderboard`, params);
      const leaderboard = results.map(_ref16 => {
        let {
          user_id: id,
          username,
          firstname: firstName,
          lastname: lastName,
          professional_email: professionalEmail,
          rank,
          progress_value: progressValue,
          jerseys,
          subgroups,
          profile_picture
        } = _ref16;
        return {
          id,
          username: username || '',
          firstName,
          lastName,
          professionalEmail,
          rank,
          progressValue,
          jerseys: jerseys?.map(type => _jersey.backendJerseyTypesMap[type]) || [],
          subGroups: subgroups?.map(_ref17 => {
            let {
              id,
              sub_group_type,
              title
            } = _ref17;
            return {
              id,
              type: subGroupTypesMap[sub_group_type],
              title
            };
          }) || [],
          profilePicture: profile_picture ? `${_environment.defaultEnvironment.backendUrl}${profile_picture}` : profile_picture
        };
      });
      return {
        count,
        leaderboard,
        hasMore: Boolean(next)
      };
    } catch (err) {
      console.error('[GeogroupService][getChallengeLeaderboard]', err);
      throw err;
    }
  }
  static async getChallengeLeaderboardBySubGroups(_ref18, challengeId, _ref19) {
    let {
      code: partner
    } = _ref18;
    let {
      subGroupDisplay
    } = _ref19;
    try {
      const results = await _http.HttpService.get('challenges', `/challenges/${challengeId}/subgroup_leaderboard`, [{
        key: 'sub_group_type',
        value: subGroupDisplay === 'teams' ? 'COMPANY_DEPARTMENT' : 'COMPANY_SITE'
      }], [], null, {
        partner
      });
      const leaderboard = results.map(_ref20 => {
        let {
          id,
          title,
          rank,
          progress_value: progressValue,
          user_count_with_not_null_progress: participants
        } = _ref20;
        return {
          id,
          username: title || '',
          rank,
          progressValue,
          participants
        };
      });
      return leaderboard;
    } catch (err) {
      console.error('[GeogroupService][getChallengeLeaderboardBySubGroups]', err);
      throw err;
    }
  }
  static async getLeaderboardBySubGroups(_ref21, _ref22) {
    let {
      code: partner,
      geoGroupId
    } = _ref21;
    let {
      jersey,
      subGroupDisplay,
      page,
      pageSize
    } = _ref22;
    try {
      const {
        count,
        results
      } = await _http.HttpService.get('v3', `/geogroups/${geoGroupId}/subgroup_windowed_activity_leaderboard`, [{
        key: 'leaderboard_type',
        value: jersey === 'distance' ? 'distance' : 'count_commuting_trace'
      }, {
        key: 'sub_group_type',
        value: subGroupDisplay === 'teams' ? 'COMPANY_DEPARTMENT' : 'COMPANY_SITE'
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }], [], null, {
        partner
      });
      const items = results.map(_ref23 => {
        let {
          id,
          rank,
          leaderboard_index: leaderboardIndex,
          title,
          progress_value: progressValue,
          counter_active_user: nbMembers,
          counter_active_user_with_home_work_trace: nbMembersWithHomeWorkTrace
        } = _ref23;
        return {
          type: 'distance',
          id,
          rank,
          leaderboardIndex,
          progressValue,
          group: {
            id,
            title,
            nbMembers,
            nbMembersWithHomeWorkTrace
          }
        };
      });
      return {
        count,
        items
      };
    } catch (err) {
      console.error('[GeogroupService][getLeaderboardBySubGroups]', err);
      throw err;
    }
  }
  static async sendEmailToParticipants(challengeId, email, usersIds, body) {
    try {
      const params = {
        users_ids: usersIds,
        contact_email: email
      };
      if (body) params.email_body = body;
      await _http.HttpService.post('challenges', `/challenges/${challengeId}/send-contact-email`, [], [], JSON.stringify(params));
    } catch (err) {
      console.error('[GeogroupService][sendEmailToParticipants]', err);
      throw err;
    }
  }
  static async getNewsPostsV2(geoGroupId) {
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/${geoGroupId}/news`);
      return data.map(parseNewsPost);
    } catch (err) {
      console.error('[GeogroupService][getNewsPostsV2]', err);
      throw err;
    }
  }
  static async getNewsPosts(geoGroupId, _ref24) {
    let {
      ordering,
      page,
      pageSize
    } = _ref24;
    try {
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('v4', `/geogroups/${geoGroupId}/news`, [{
        key: 'ordering',
        value: ordering
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }]);
      return {
        count,
        hasNext: Boolean(next),
        results: results.map(parseNewsPost)
      };
    } catch (err) {
      console.error('[GeogroupService][getNewsPosts]', err);
      throw err;
    }
  }
  static async getNewsPost(_ref25) {
    let {
      partner,
      geoGroupId,
      newsPostId
    } = _ref25;
    try {
      const data = await _http.HttpService.get('v4', `/geogroups/${geoGroupId}/news/${newsPostId}`, [], [], null, {
        partner: partner.code
      });
      return parseNewsPost(data);
    } catch (err) {
      console.error('[GeogroupService][getNewsPost]', err);
      throw err;
    }
  }
  static async addNewsPost(geoGroupId, props, partner, anonymous) {
    try {
      const formData = new FormData();
      Object.keys(props).forEach(key => {
        const value = props[key];
        if (value !== undefined) {
          if (typeof value === 'number') formData.append(key, `${value}`);else formData.append(key, value);
        }
      });
      const data = await _http.HttpService.post('v4', `/geogroups/${geoGroupId}/news`, anonymous ? [{
        key: 'anonymous',
        value: true
      }] : [], [], formData, partner ? {
        partner: partner.code
      } : undefined);
      return parseNewsPost(data);
    } catch (err) {
      console.error('[GeogroupService][addNewsPost]', err);
      throw err;
    }
  }
  static async updateNewsPost(geoGroupId, id, props, partner) {
    try {
      const formData = new FormData();
      Object.keys(props).forEach(key => {
        const value = props[key];
        if (value) formData.append(key, value);
      });
      const data = await _http.HttpService.put('v4', `/geogroups/${geoGroupId}/news/${id}`, [], [], formData, partner ? {
        partner: partner.code
      } : undefined);
      if (props.photo === null) await _http.HttpService.delete('v2', `/geogroups/${geoGroupId}/news/${id}/photo`);
      return parseNewsPost(data);
    } catch (err) {
      console.error('[GeogroupService][updateNewsPost]', err);
      throw err;
    }
  }
  static async removeNewsPost(geoGroupId, _ref26, partner) {
    let {
      id
    } = _ref26;
    try {
      await _http.HttpService.delete('v4', `/geogroups/${geoGroupId}/news/${id}`, [], [], null, partner ? {
        partner: partner.code
      } : undefined);
      return true;
    } catch (err) {
      console.error('[GeogroupService][removeNewsPost]', err);
      throw err;
    }
  }
  static async getStats(_ref27) {
    let {
      geoGroupId,
      period
    } = _ref27;
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/${geoGroupId}/stats`, [...(0, _models.periodToParams)(period)]);
      const stats = period.unit === 'all_period' ? new _models.GeogroupStats(data[0]?.count_manually_joined_members || 0, []) : parseGeogroupStats(data);
      return stats;
    } catch (err) {
      console.error('[GeogroupService][getStats]', err);
      throw err;
    }
  }
  static async getActivityStats(_ref28, period) {
    let {
      geoGroupId,
      area
    } = _ref28;
    if (geoGroupId === null) throw new Error('partner has no geogroup identifier');
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/${geoGroupId}/activities`, [...(0, _models.periodToParams)(period)]);
      if (period.unit === 'all_period') {
        const {
          counter_distance: totalDistance,
          counter_home_work_reference_trips_distance: totalHomeWorkDistance,
          counter_duration: totalDuration,
          counter_home_work_reference_trips_duration: totalHomeWorkDuration,
          counter_traces: totalNbRoutes,
          counter_home_work_reference_trips_traces: totalHomeWorkJourneys,
          counter_active_user: nbActiveUsers,
          counter_active_user_with_home_work_trace: nbActiveUsersWithHomeWorkTrace
        } = data[0];
        return new _models.ActivityStats(0, totalDistance, totalDuration, totalNbRoutes, [], totalHomeWorkDistance, totalHomeWorkDuration, totalHomeWorkJourneys, nbActiveUsers, nbActiveUsersWithHomeWorkTrace);
      }
      const computedRouteStats = area ? await _stats2.StatsService.getComputedRouteStats(period) : null;
      data.sort((a, b) => a.activity_day.localeCompare(b.activity_day));
      const stats = parseActivityStats(data, computedRouteStats);
      return stats;
    } catch (err) {
      console.error('[GeogroupService][getActivityStats]', err);
      throw err;
    }
  }
  static async getSubgroupsStats(_ref29) {
    let {
      geoGroupId,
      subgroupType,
      period,
      ordering
    } = _ref29;
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/${geoGroupId}/sub_group_aggregated_stats`, [{
        key: 'sub_group_type',
        value: backendSubGroupTypesMap[subgroupType]
      }, {
        key: 'ordering',
        value: ordering
      }, ...(0, _models.periodToParams)(period)]);
      return data.map(parseGeogroupSubgroupsStats);
    } catch (err) {
      console.error('[GeogroupService][getSubgroupsStats]', err);
      throw err;
    }
  }
  static async getAverageJourneyStats(_ref30) {
    let {
      geoGroupId,
      period,
      journeysType
    } = _ref30;
    try {
      const data = await _http.HttpService.get('geogroups', `/geogroups/${geoGroupId}/average_trace_stats`, [{
        key: 'only_home_work_traces',
        value: journeysType === 'homeWork'
      }, ...(0, _models.periodToParams)(period)]);
      return parseAverageJourneyStats(data);
    } catch (err) {
      console.error('[GeogroupService][getAverageJourneyStats]', err);
      throw err;
    }
  }
  static async getStatements(_ref31) {
    let {
      event,
      groupId
    } = _ref31;
    const {
      startDate,
      endDate
    } = event;
    const strStartDate = startDate.toISOString().split('T')[0].split('-').reverse().join('-');
    const strEndDate = endDate.toISOString().split('T')[0].split('-').reverse().join('-');
    try {
      const {
        results
      } = await _http.HttpService.get('geogroups', `/geogroups/${groupId}/manual_activities`, [{
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: strStartDate
      }, {
        key: 'date_end',
        value: strEndDate
      }]);
      const statements = results.map(parseGeogroupStatement);
      return statements;
    } catch (err) {
      console.error('[GeogroupService][getStatements]', err);
      throw err;
    }
  }
  static async addStatement(_ref32) {
    let {
      groupId,
      strDate: activity_day,
      distance: counter_distance,
      nbTraces: counter_traces
    } = _ref32;
    try {
      const data = await _http.HttpService.post('geogroups', `/geogroups/${groupId}/manual_activities`, [], [], JSON.stringify({
        activity_day,
        counter_distance,
        counter_traces
      }));
      const statement = parseGeogroupStatement(data);
      return statement;
    } catch (err) {
      console.error('[GeogroupService][addStatement]', err);
      throw err;
    }
  }
  static async updateStatement(id, _ref33) {
    let {
      groupId,
      strDate: activity_day,
      distance: counter_distance,
      nbTraces: counter_traces
    } = _ref33;
    try {
      const data = await _http.HttpService.patch('geogroups', `/geogroups/${groupId}/manual_activities/${id}`, [], [], JSON.stringify({
        activity_day,
        counter_distance,
        counter_traces
      }));
      const statement = parseGeogroupStatement(data);
      return statement;
    } catch (err) {
      console.error('[GeogroupService][updateStatement]', err);
      throw err;
    }
  }
  static async likeNewsPost(_ref34) {
    let {
      newsPostId
    } = _ref34;
    try {
      await _http.HttpService.put('geogroups', `/geo_group_news/${newsPostId}/like`);
      return true;
    } catch (err) {
      console.error('[GeogroupService][likeNewsPost]', err);
      throw err;
    }
  }
  static async unlikeNewsPost(_ref35) {
    let {
      newsPostId
    } = _ref35;
    try {
      await _http.HttpService.delete('geogroups', `/geo_group_news/${newsPostId}/like`);
      return true;
    } catch (err) {
      console.error('[GeogroupService][unlikeNewsPost]', err);
      throw err;
    }
  }
}
exports.GeogroupService = GeogroupService;
const backendSubGroupTypesMap = {
  companyTeam: 'COMPANY_DEPARTMENT',
  companySite: 'COMPANY_SITE'
};
const subGroupTypesMap = {
  COMPANY_DEPARTMENT: 'companyTeam',
  COMPANY_SITE: 'companySite'
};
const automaticChallengeStatusMap = {
  AUTOMATIC_DISTANCE: 'automaticDistance',
  DISABLED: 'disabled',
  EDITORIALIZED: 'editorialized'
};
const backendAutomaticChallengeStatusMap = {
  automaticDistance: 'AUTOMATIC_DISTANCE',
  disabled: 'DISABLED',
  editorialized: 'EDITORIALIZED'
};
function parseGeogroup(_ref36, backendUrl) {
  let {
    id,
    type,
    area,
    title,
    code,
    photo,
    geo_point,
    total_members,
    total_distance,
    automatic_challenge_creation,
    size_range,
    address,
    parent,
    sub_group_type,
    partner,
    publication_status,
    banner_picture,
    description,
    link_label,
    link_url,
    open_membership
  } = _ref36;
  return new _models.Geogroup(id, backendTypesMap[type], area, title || '', code, photo ? `${backendUrl}${photo}` : null, geo_point ? new _models.Place(id, geo_point, address ? sub_group_type === 'COMPANY_SITE' ? {
    primaryText: title || '',
    secondaryText: address
  } : {
    primaryText: address
  } : undefined) : null, total_members, total_distance, automatic_challenge_creation ? automaticChallengeStatusMap[automatic_challenge_creation] : 'disabled', code === 'lyon', size_range, parent, sub_group_type ? subGroupTypesMap[sub_group_type] : sub_group_type, partner ? (0, _partner.parsePartner)({
    partner
  }) : partner, publication_status ? _publicationStatus.publicationStatuses[publication_status] : undefined, banner_picture ? `${backendUrl}${banner_picture}` : undefined, description, link_url ? {
    url: link_url,
    label: link_label || null
  } : null, Boolean(open_membership));
}
const userGeogroupStatuses = {
  AUTOMATICALLY_JOIN: 'automaticallyJoin',
  AUTOMATICALLY_QUIT: 'automaticallyQuit',
  MANUALLY_JOIN: 'manuallyJoin',
  MANUALLY_QUIT: 'manuallyQuit',
  WAITING_FOR_APPROVAL: 'waitingForApproval'
};
const backendUserGeogroupStatuses = {
  automaticallyJoin: 'AUTOMATICALLY_JOIN',
  automaticallyQuit: 'AUTOMATICALLY_QUIT',
  manuallyJoin: 'MANUALLY_JOIN',
  manuallyQuit: 'MANUALLY_QUIT',
  waitingForApproval: 'WAITING_FOR_APPROVAL'
};
function parseUserGeogroup(_ref37, backendUrl) {
  let {
    type,
    group,
    join_date,
    last_activity_date,
    stats,
    automatic_challenge_creation,
    user_type
  } = _ref37;
  return new _models.UserGeogroup(userGeogroupStatuses[type], parseGeogroup(group, backendUrl), (0, _moment.default)(join_date), (0, _moment.default)(last_activity_date), stats ? {
    distance: stats.distance,
    duration: stats.duration,
    nbTraces: stats.traces,
    nbItineraries: stats.itineraries,
    nbReports: stats.reports,
    nbReviews: stats.reviews
  } : undefined, automatic_challenge_creation ? automaticChallengeStatusMap[automatic_challenge_creation] : 'disabled', user_type === 'ADMIN');
}
function parseGeogroupMember(_ref38) {
  let {
    id,
    username,
    join_date,
    last_activity_date,
    meters
  } = _ref38;
  if (!id) return null;
  return new _models.GeogroupMember(id, username, (0, _moment.default)(join_date), (0, _moment.default)(last_activity_date), meters || 0);
}
const challengeTypes = {
  TRAVELED_DISTANCE: 'traveledDistance',
  CYCLING_DAYS: 'cyclingDays'
};
const collaborationTypes = {
  INDIVIDUAL: 'individual',
  EVERYONE: 'global'
};
const traceTypes = {
  ALL: 'all',
  COMMUTING: 'commuting',
  NOT_APPLICABLE: 'notApplicable'
};
function parseChallenge(_ref39, backendUrl) {
  let {
    id,
    target_type,
    collaboration_type,
    trace_type,
    start_datetime,
    end_datetime,
    title,
    description,
    photo,
    details_url,
    total_members,
    progress_value,
    target_value,
    publication_status,
    event,
    default_sub_group_display,
    group
  } = _ref39;
  let type = challengeTypes[target_type];
  if (target_type === 'TRACES_COUNT') {
    if (trace_type === 'COMMUTING') type = 'homeWorkJourneysCount';else if (trace_type === 'ALL') type = 'journeysCount';
  }
  if (!type) return null;
  return new _models.Challenge(id, type, default_sub_group_display && ['COMPANY_DEPARTMENT', 'COMPANY_SITE'].includes(default_sub_group_display) ? 'teams' : collaborationTypes[collaboration_type], traceTypes[trace_type], (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), title || `Challenge #${id}`, description, photo ? `${backendUrl}${photo}` : null, details_url, total_members, progress_value, target_value || 0, _publicationStatus.publicationStatuses[publication_status], event, default_sub_group_display === 'COMPANY_DEPARTMENT' ? 'teams' : default_sub_group_display === 'COMPANY_SITE' ? 'sites' : null, group);
}
function parseChallengeTemplate(eventId, _ref40, backendUrl) {
  let {
    id,
    target_type,
    collaboration_type,
    trace_type,
    start_datetime,
    end_datetime,
    title,
    description,
    photo,
    details_url,
    target_value,
    default_sub_group_display
  } = _ref40;
  let type = challengeTypes[target_type];
  if (target_type === 'TRACES_COUNT') {
    if (trace_type === 'COMMUTING') type = 'homeWorkJourneysCount';else if (trace_type === 'ALL') type = 'journeysCount';
  }
  if (!type) return null;
  return new _models.ChallengeTemplate(id, type, default_sub_group_display && ['COMPANY_DEPARTMENT', 'COMPANY_SITE'].includes(default_sub_group_display) ? 'teams' : collaborationTypes[collaboration_type], traceTypes[trace_type], (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), title || `Challenge #${id}`, description, photo ? photo.indexOf('https') === -1 && photo.indexOf('http') === -1 ? `${backendUrl}${photo}` : photo : null, details_url, target_value || 0, eventId, default_sub_group_display === 'COMPANY_DEPARTMENT' ? 'teams' : default_sub_group_display === 'COMPANY_SITE' ? 'sites' : null);
}
function parseNewsPost(_ref41) {
  let {
    id,
    geo_group,
    creator,
    created,
    text,
    photo,
    link_label,
    link_url,
    publication_date,
    type,
    likes_count,
    liked
  } = _ref41;
  const typesMap = {
    CHALLENGE_END: 'challengeEnd',
    CHALLENGE_OBJECTIVE_100: 'challengeObjective100',
    CHALLENGE_OBJECTIVE_25: 'challengeObjective25',
    CHALLENGE_OBJECTIVE_50: 'challengeObjective50',
    CHALLENGE_OBJECTIVE_75: 'challengeObjective75',
    CHALLENGE_START: 'challengeStart',
    CHALLENGE_TOP_RANKING: 'challengeTopRanking',
    MANUAL: 'manual'
  };
  return new _models.GeogroupNewsPost(id, geo_group ? {
    id: geo_group.id,
    icon: geo_group.photo ? `${_environment.defaultEnvironment.backendUrl}${geo_group.photo}` : geo_group.photo,
    title: geo_group.title
  } : null, creator ? {
    id: creator.id,
    profilePicture: creator.profile_picture ? `${_environment.defaultEnvironment.backendUrl}${creator.profile_picture}` : creator.profile_picture,
    username: creator.username
  } : null, (0, _moment.default)(created), text, photo ? photo.url : null, link_url ? {
    url: link_url,
    label: link_label
  } : null, publication_date ? (0, _moment.default)(publication_date) : null, type && typesMap[type], likes_count, liked);
}
function parseGeogroupStats(data) {
  let maxMembers = 0;
  const stats = {};
  data.forEach(_ref42 => {
    let {
      unit: strDate,
      count_manually_joined_members: nbMembers
    } = _ref42;
    const date = (0, _stats.parseStatsDate)({
      strDate
    });
    if (date) {
      const strDate = date.format('l');
      stats[strDate] = {
        strDate,
        date,
        nbMembers
      };
      maxMembers = Math.max(maxMembers, nbMembers);
    }
  });
  return new _models.GeogroupStats(maxMembers, Object.values(stats));
}
function parseActivityStats(data, computedRouteStats) {
  let totalDistance = 0;
  let totalHomeWorkDistance = 0;
  let totalDuration = 0;
  let totalHomeWorkDuration = 0;
  let totalNbRoutes = 0;
  let totalHomeWorkJourneys = 0;
  let maxActiveUsers = 0;
  let maxActiveUsersWithHomeWorkTrace = 0;
  const stats = {};
  data.forEach(_ref43 => {
    let {
      activity_day: strDate,
      counter_distance: distance,
      counter_home_work_reference_trips_distance: homeWorkDistance,
      counter_duration: duration,
      counter_home_work_reference_trips_duration: homeWorkDuration,
      counter_traces: nbRoutes,
      counter_home_work_reference_trips_traces: homeWorkJourneys,
      counter_active_user: nbActiveUsers,
      counter_active_user_with_home_work_trace: nbActiveUsersWithHomeWorkTrace
    } = _ref43;
    const date = (0, _stats.parseStatsDate)({
      strDate
    });
    if (date) {
      const strDate = date.format('l');
      stats[strDate] = {
        strDate,
        date,
        nbComputedRoutes: 0,
        distance,
        homeWorkDistance,
        duration,
        homeWorkDuration,
        nbRoutes,
        homeWorkJourneys,
        nbActiveUsers,
        nbActiveUsersWithHomeWorkTrace
      };
    }
    totalDistance += distance;
    totalHomeWorkDistance = totalHomeWorkDistance + (homeWorkDistance || 0);
    totalDuration += duration;
    totalHomeWorkDuration = totalHomeWorkDuration + (homeWorkDuration || 0);
    totalNbRoutes += nbRoutes;
    totalHomeWorkJourneys = totalHomeWorkJourneys + (homeWorkJourneys || 0);
    maxActiveUsers = Math.max(maxActiveUsers, nbActiveUsers || 0);
    maxActiveUsersWithHomeWorkTrace = Math.max(maxActiveUsersWithHomeWorkTrace, nbActiveUsersWithHomeWorkTrace || 0);
  });
  computedRouteStats?.data.forEach(_ref44 => {
    let {
      date,
      count
    } = _ref44;
    const strDate = date.format('l');
    if (stats[strDate]) stats[strDate].nbComputedRoutes = count;else stats[strDate] = {
      strDate,
      date,
      nbComputedRoutes: count,
      distance: 0,
      homeWorkDistance: 0,
      duration: 0,
      homeWorkDuration: 0,
      nbRoutes: 0,
      homeWorkJourneys: 0,
      nbActiveUsers: 0,
      nbActiveUsersWithHomeWorkTrace: 0
    };
  });
  return new _models.ActivityStats(computedRouteStats?.count || 0, totalDistance, totalDuration, totalNbRoutes, Object.values(stats), totalHomeWorkDistance, totalHomeWorkDuration, totalHomeWorkJourneys, maxActiveUsers, maxActiveUsersWithHomeWorkTrace);
}
function parseGeogroupSubgroupsStats(_ref45) {
  let {
    group_title: subgroupTitle,
    stats_counter_duration: duration,
    stats_counter_distance: distance,
    stats_counter_traces: journeys,
    stats_counter_home_work_reference_trips_duration: homeWorkDuration,
    stats_counter_home_work_reference_trips_distance: homeWorkDistance,
    stats_counter_home_work_reference_trips_traces: homeWorkJourneys,
    counter_active_user: activeMembers,
    counter_active_user_with_home_work_trace: activeMembersWithHomeWorkJourney
  } = _ref45;
  return {
    subgroupTitle,
    duration,
    distance,
    journeys,
    homeWorkDuration,
    homeWorkDistance,
    homeWorkJourneys,
    activeMembers,
    activeMembersWithHomeWorkJourney
  };
}
function parseAverageJourneyStats(_ref46) {
  let {
    average_duration_in_seconds: averageDuration,
    average_distance_in_meters: averageDistance,
    duration_stats,
    distance_stats
  } = _ref46;
  return {
    averageDuration,
    averageDistance,
    durationStats: duration_stats.map(_ref47 => {
      let {
        greater_than: min,
        less_than_or_equal: max,
        trace_count: count
      } = _ref47;
      return {
        min,
        max,
        count
      };
    }),
    distanceStats: distance_stats.map(_ref48 => {
      let {
        greater_than: min,
        less_than_or_equal: max,
        trace_count: count
      } = _ref48;
      return {
        min,
        max,
        count
      };
    })
  };
}
function parseGeogroupStatement(_ref49) {
  let {
    id,
    activity_day,
    counter_distance,
    counter_traces
  } = _ref49;
  return new _models.GeogroupStatement(id, activity_day, Math.round((counter_distance || 0) / 100) / 10, counter_traces || 0);
}