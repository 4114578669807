"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dayBadgesMap = exports.dayBadges = exports.conditionalBadgesMap = exports.conditionalBadges = exports.badgeLevelsMap = exports.badgeLevels = void 0;
var _christmas = _interopRequireDefault(require("../assets/images/badges/christmas.svg"));
var _contributor = _interopRequireDefault(require("../assets/images/badges/contributor.svg"));
var _distance = _interopRequireDefault(require("../assets/images/badges/distance.svg"));
var _endOfTheYear = _interopRequireDefault(require("../assets/images/badges/end-of-the-year.svg"));
var _firstOfTheYear = _interopRequireDefault(require("../assets/images/badges/first-of-the-year.svg"));
var _halloween = _interopRequireDefault(require("../assets/images/badges/halloween.svg"));
var _longDistanceIcon = _interopRequireDefault(require("../assets/images/badges/long-distance-icon.svg"));
var _longDistance = _interopRequireDefault(require("../assets/images/badges/long-distance.svg"));
var _nightBirdIcon = _interopRequireDefault(require("../assets/images/badges/night-bird-icon.svg"));
var _nightBird = _interopRequireDefault(require("../assets/images/badges/night-bird.svg"));
var _regularity = _interopRequireDefault(require("../assets/images/badges/regularity.svg"));
var _verticalGainIcon = _interopRequireDefault(require("../assets/images/badges/vertical-gain-icon.svg"));
var _verticalGain = _interopRequireDefault(require("../assets/images/badges/vertical-gain.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const badgeLevels = exports.badgeLevels = ['cyclingDistance', 'cyclingMaxDistance', 'nightBird', 'verticalGain', 'regularity', 'cyclingMaxDistanceOverOneMonth', 'contributor'];
const badgeLevelsMap = exports.badgeLevelsMap = {
  cyclingDistance: {
    bgColor: '#FFECA9',
    color: '#BB8A1A',
    image: _distance.default,
    labelKey: 'commons.achievements.badge_titles.cycling_distance',
    progressKey: 'commons.achievements.progress.cycling_distance',
    nextLevelKey: 'commons.achievements.next_level.cycling_distance'
  },
  regularity: {
    bgColor: '#CDF5E3',
    color: '#048760',
    image: _regularity.default,
    labelKey: 'commons.achievements.badge_titles.regularity',
    progressKey: 'commons.achievements.progress.regularity',
    nextLevelKey: 'commons.achievements.next_level.regularity'
  },
  contributor: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    image: _contributor.default,
    labelKey: 'commons.achievements.badge_titles.contributor',
    progressKey: 'commons.achievements.progress.contributor',
    nextLevelKey: 'commons.achievements.next_level.contributor'
  },
  cyclingMaxDistance: {
    bgColor: '#FFECA9',
    color: '#BB8A1A',
    image: _distance.default,
    labelKey: 'commons.achievements.badge_titles.cycling_max_distance',
    progressKey: '',
    nextLevelKey: ''
  },
  nightBird: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    image: _nightBird.default,
    labelKey: 'commons.achievements.badge_titles.night_bird',
    progressKey: 'commons.achievements.progress.night_bird',
    nextLevelKey: 'commons.achievements.next_level.cycling_distance'
  },
  verticalGain: {
    bgColor: '#C9E9F8',
    color: '#006692',
    image: _verticalGain.default,
    labelKey: 'commons.achievements.badge_titles.vertical_gain',
    progressKey: 'commons.achievements.progress.vertical_gain',
    nextLevelKey: 'commons.achievements.next_level.cycling_distance'
  },
  cyclingMaxDistanceOverOneMonth: {
    bgColor: '#CDF5E3',
    color: '#048760',
    image: _regularity.default,
    labelKey: 'commons.achievements.badge_titles.cycling_max_distance_over_one_month',
    progressKey: '',
    nextLevelKey: ''
  }
};
const conditionalBadges = exports.conditionalBadges = ['verticalGain', 'longDistance', 'nightBird'];
const conditionalBadgesMap = exports.conditionalBadgesMap = {
  longDistance: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    image: _longDistance.default,
    icon: _longDistanceIcon.default,
    labelKey: 'commons.achievements.badge_titles.long_distance',
    progressKey: 'commons.achievements.progress.long_distance'
  },
  nightBird: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    image: _nightBird.default,
    icon: _nightBirdIcon.default,
    labelKey: 'commons.achievements.badge_titles.night_bird',
    progressKey: 'commons.achievements.progress.night_bird'
  },
  verticalGain: {
    bgColor: '#C9E9F8',
    color: '#006692',
    image: _verticalGain.default,
    icon: _verticalGainIcon.default,
    labelKey: 'commons.achievements.badge_titles.vertical_gain',
    progressKey: 'commons.achievements.progress.vertical_gain'
  }
};
const dayBadges = exports.dayBadges = ['HALLOWEEN', 'CHRISTMAS_DAY', 'NEW_YEARS_EVE', 'NEW_YEARS_DAY'];
const dayBadgesMap = exports.dayBadgesMap = {
  HALLOWEEN: {
    bgColor: '#D7C7F6',
    color: '#491EA1',
    image: _halloween.default,
    labelKey: 'commons.achievements.badge_titles.halloween',
    descriptionKey: 'commons.achievements.badge_descriptions.halloween'
  },
  CHRISTMAS_DAY: {
    bgColor: '#FFDCC1',
    color: '#C14F0E',
    image: _christmas.default,
    labelKey: 'commons.achievements.badge_titles.christmas_day',
    descriptionKey: 'commons.achievements.badge_descriptions.christmas_day'
  },
  NEW_YEARS_EVE: {
    bgColor: '#CDF5E3',
    color: '#048760',
    image: _endOfTheYear.default,
    labelKey: 'commons.achievements.badge_titles.new_year_eve',
    descriptionKey: 'commons.achievements.badge_descriptions.new_year_eve'
  },
  NEW_YEARS_DAY: {
    bgColor: '#FFEBF5',
    color: '#C6377C',
    image: _firstOfTheYear.default,
    labelKey: 'commons.achievements.badge_titles.new_year_day',
    descriptionKey: 'commons.achievements.badge_descriptions.new_year_day'
  }
};